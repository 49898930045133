<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>نبذه عن إداره دعم النزاهة</h3></div>

    <v-divider></v-divider>
    <v-container>
      <div>
        <v-row>
          <v-col cols="12" lg="12" sm="12" xs="12">
            <v-card class="changeTitleStyle mb-6">
              <v-card-title class="changeTitleStyle">
                <h3>التعريف</h3>
              </v-card-title>
              <v-card-text class="importantSite">
                <h3>
                  إدارة دعم النزاهة بشركة مياه الشرب والصرف الصحي بأسيوط والوادي
                  الجديد تتبع الإدارة العامة لشئون مجلس الإدارة، وتحت الإشراف
                  المباشر لإدارة الجودة والنزاهة بالشركة القابضة بالقاهرة وتنطلق
                  من الهدف من وراء إنشائها وهو توفير الخدمات بكفاءة وفاعلية
                  للوفاء بتوقعات أصحاب المصالح، والربط بين متطلبات النزاهة
                  والشفافية وبين المتطلبات التي تفرض على الشركة من خلال الأجهزة
                  السيادية والتنظيمية من داخل وخارج قطاع المياه .
                </h3>
                <br />
              </v-card-text>
            </v-card>

            <v-card class="changeTitleStyle mb-6">
              <v-card-title class="changeTitleStyle">
                <h3>الإنجازات</h3>
              </v-card-title>
              <v-card-text class="importantSite">
                <h3>
                  بناء على تكليفات الشركة القابضة،وتوجيهات السيد لواء أركان حرب
                  مهندس رئيس مجلس الإدارة والعضو المنتدب لشركة مياه الشرب والصرف
                  الصحي بأسيوط والوادي الجديد .
                  <br />
                  قامت إدارة دعم النزاهة بشركة مياه الشرب والصرف الصحي باسيوط
                  والوادي الجديد، بتحديد العمليات الخاصة بالمخازن وكذلك العمليات
                  ذات الصلة، والتي لها تأثير مباشر على المخازن .
                  <br />
                  وفيما يخص العمل بالدليل التجاري الموحد بالقطاع التجاري
                  بالشركات التابعة، تم عقد عديد من ورش العمل الخاصة بتطبيق
                  الدليل التجارى الموحد بالقطاع التجارى وتم رصد عدة ملاحظات خاصة
                  بالاجراءات تؤثر على اجراءات سير العمل بشركة مياه أسيوط مما
                  تأخذه من وقت وادوات
                  <br />
                  وعليه فأن اجراءات العمل بشركة مياه أسيوط تتم بشكل اسرع وأدق
                  خصوصا دورة الاصدار و التحصيل حيث أن عدد أيام التحصيل بناءا على
                  نظام وجدول العمل بمركز الاصدار يتيح لكل فرع من (29 إلى 30 يوم
                  تحصيل ) . كما يتم مراجعة جميع تقارير الأصدار من قبل إدارة
                  المراجعة بمركز الاصدار وإبداء جميع الملاحظات وتلافيها قبل
                  طباعة الفواتير .
                  <br />
                  أما بالنسبة لمراكز خدمة العملاء يتم التعامل من خلال الشباك
                  الواحد بمراكز خدمة العملاء مما يقلل الاحتياج لاعداد كثيرة من
                  العاملين بالمركز الواحد ويؤدى إلى سرعة الانجاز وعدم ضياع
                  المستندات . وعليه تم إعتماد الدليل التجارى الموحد بالقطاع
                  التجارى على مستوى الشركات مع مراعاة نظام الإصدار والتحصيل
                  المتبع حالياً بالشركة لحين توحيد النظم والبرامج الإلكترونية
                  والمراجعة مع الشركة القابضة
                  <br />وبناء على الخطط التي تم وضعها وإعتمادها ومراجعتها من
                  الشركة القابضة لعمليات القطاع التجاري ( عدد ستة عمليات) تم رصد
                  وقياس نسب مؤشرات الأداء و أهمها فيما يلي:
                </h3>
                <br />
              </v-card-text>

              <v-expansion-panels v-model="panel" multiple class="pa-4 " >
                <v-expansion-panel v-animate-onscroll="'fade-in-right'"
                  v-for="(item, i) in ArrayOFAnElsherka"
                  :key="i"
                  class="changeTitleStyle"
                >
                  <v-expansion-panel-header class="fade-in titlePanel">
                    {{ item.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul v-if="item.subtext">
                      <li v-for="i in item.subtext" :key="i">
                        <h4 class="suntextPanel">{{ i.subtext }}</h4>
                      </li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels></v-card
            >
            <v-card class="changeTitleStyle mb-3">
              <v-card-title class="changeTitleStyle">
                <h3>موضوعات أخري</h3>
              </v-card-title>
              <v-card-text class="importantSite">
                <h3>
                  • تم دراسة موضوع مقدم من إدارة المخالفات بالقطاع التجاري بخصوص
                  تحصيل مستحقات الشركة في حالة إنشاء خطوط تعدي على شبكة المياه
                  أو الصرف، وقد تم إعداد الدراسة المطلوبة وتم تعميمها على جميع
                  مناطق الشركة
                </h3>
                <h3>
                  • وقامت الإدارة بدراسة مشكلات المحصلين التي تعوق عملية التحصيل
                  والحلول لمقترحة، وقد تم اعتماد الحلول وتعميمها ( مثال :
                  المنازل المهجورة في بعض المناطق وكذلك المغلقة معظم شهور السنة
                  في دير درنكة).
                </h3>
                <br />
              </v-card-text>
            </v-card>
          </v-col>
         
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      panel: [0, 1, 2, 3, 4, 5],
      ArrayOFAnElsherka: [
        {
          title: "التعاقدات والإشتراكات:  ",

          subtext: [
            {
              id: "1",
              subtext:
                " 1.	انخفاض شكاوى العملاء المتضررين من عدم اتمام طلباتهم إلى الصفر.",
            },
            {
              id: "2",

              subtext:
                " 2.	انخفاض عدد المقايسات التي تم رفضها لأسباب غير منطقية إلى الصفر، وذلك كنتيجة لتدريب العاملين . ",
            },
          ],
        },
        {
          title: "المقايسات : ",

          subtext: [
            {
              id: "1",
              subtext:
                "1.	انخفاض نسبة شكاوى العملاء من طول المدة الزمنية لإتمام المعاينة والتوصيل والتركيب من 33% في 1/7/2019 إلى 10% في 1/7/2020 ثم 6% في 1/1/2021 . ",
            },
            {
              id: "2",

              subtext:
                "2.	انخفاض نسبة شكاوى العملاء من عدم إصدار فواتير رغم تركيب العداد إلى الصفر، وذلك نتيجة ميكنة خدمة العملاء وانتظام الدورة المستندية بمركز الإصدار وتركيب العدادات مسبقة الدفع. ",
            },
          ],
        },
        {
          title: "العدادات مسبقة الدفع :",
          subtext: [
            {
              id: "1",
              subtext:
                "1.	انخفاض نسبة شكاوى العملاء من تأخر تركيب العداد بالرغم من سداد كافة المستحقات، حتى وصلت إلى 3% تقريباً في 1/10/2020 . ",
            },
            {
              id: "2",
              subtext:
                "2.	ارتفاع نسبة تركيب العدادات مسبقة الدفع مقارنة بالشهور السابقة، من 3209 في 1/7/2019 إلى 3752 في 1/10/2020  . ",
            },
          ],
        },
        {
          title: "مراجعة الإيرادات  :",
          subtext: [
            {
              id: "1",
              subtext:
                "1.	انخفاض نسبة العجز لدى المحصلين إلى الصفر، وذلك بسبب تطبيق الفاتورة التراكمية وزيادة عدد المحصلين ",
            },
            {
              id: "2",
              subtext:
                "2.انخفاض شكاوى العملاء من سداد الفاتورة وإعادة تحصيلها مرة أخرى ـ إلى الصفر، وذلك بسبب تطبيق الفاتورة التراكمية وعدم تطبيق التحصيل الإلكتروني .  ",
            },
            {
              id: "3",
              subtext:
                "3.	انخفاض نسبة الفواتير المرتجعة وغير الملغاه من 368816 في 1/7/2019 إلى 227631 في 1/7/2020 ثم 169826 في 1/10/2020  . ",
            },

            {
              id: "4",
              subtext:
                "4.	زيادة نسبة التسويات وبالتالي انخفاض شكاوى العملاء من وجود رصيد سابق رغم السداد، حيث كانت 1152 في 1/7/2019 و 3918 في 1/7/2020 ثم 4039 في 1/10/2020 .",
            },
          ],
        },
        {
          title: "عملية تركيب العدادات   :",
          subtext: [
            {
              id: "1",
              subtext:
                "1.	بالنسبة لخطط تركيب العدادت يوجد صعوبة في تحديد القيمة الصفرية في الفترة من 1/7/2020 حتى 31/12/2020 وذلك لظروف غلق مراكز خدمة العملاء لظروف جائحة كورونا، وعند فتح مراكز خدمة العملاء وبدء التعامل مع الجمهور تم اتخاذ إجراءات تغيير العدادات المعطلة وتركيب العدادت السليمة حيث تم تركيب (50000) عداد وهي نسبة كبيرة في فترة زمنية قصيرة، ثم تم فتح تركيب العدادات الكودية، وتم البدء في تطبيق خطة الشركة القابضة لإحلال وتغيير العدادات القديمة بعدادات مسبقة الدفع.  ",
            },
            {
              id: "2",
              subtext: "2.	تم تركيب عدد (5) عدادات ميكانيكية ذات أقطار كبيرة.  ",
            },
          ],
        },
        {
          title: "عملية التحصيل    :",
          subtext: [
            {
              id: "1",
              subtext:
                "1.	ارتفاع نسب تحصيل بالشركة من الإصدار (بدون الحكومي) من 94% عام 2018 إلى 97% عام 2019/2020 وذلك بعد تطبيق الجدول الزمنى لخطة التحصيل، وزيادة كفاءة عملية قراءة العدادات والقضاء على القراءات العشوائية وانعدام شكاوى العملاء وانهاء مشكلة التراكمات والمتأخرات ومن ثم زيادة جودة الخدمة. ",
            },
            {
              id: "2",
              subtext:
                "2.	تفعيل خدمة الموبايل ابليكيشن لتلقي الشكاوى، وتم تفعيل استخدام هذه الخدمة في الإستعلام وسداد الفواتير.   ",
            },
            {
              id: "3",
              subtext:
                "3.	عمل دورات تدريبية للمحصلين مما زاد من معدل التوريد اليومي لكل محصل من  5.2 % عام 2019 إلى 4% عام 2020وحتى الان",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style >
h3 {
  font-family: Arial, Helvetica, sans-serif;
}
.changeTitleStyle {
  background: rgba(222, 184, 135, 0.192) !important;
}
.titlePanel {
  background: rgba(135, 212, 222, 0.192) !important;
  font-weight: bold;
  font-size: 18px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.suntextPanel {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
</style>